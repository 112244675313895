/* Libraries */
@use 'grapesjs/dist/css/grapes.min.css';
@use 'swiper/scss';
@use 'swiper/scss/navigation';
@use 'swiper/scss/pagination';
@use '@ionic/angular/css/ionic-swiper';
@use "../node_modules/angular-calendar/css/angular-calendar.css";
@use '@blueprintjs/icons/lib/css/blueprint-icons.css';
@use '@blueprintjs/core/lib/css/blueprint.css';

/* pipeline Core */
@use "./src/theme/components/alert";
@use "./src/theme/components/badge";
@use "./src/theme/components/base";
@use "./src/theme/components/curve";
@use "./src/theme/components/dragula";
@use "./src/theme/components/introjs";
@use "./src/theme/components/grid";
@use "./src/theme/components/loader";
@use "./src/theme/components/lottie";
@use "./src/theme/components/menu";
@use "./src/theme/components/slides";
@use "./src/theme/components/polotno";
@use "./src/theme/components/profileFab";
@use "./src/theme/components/progress";
@use "./src/theme/components/button";
@use "./src/theme/components/card";
@use "./src/theme/components/editor";
@use "./src/theme/components/flag";
@use "./src/theme/components/headlineToolbar";
@use "./src/theme/components/img/logo";
@use "./src/theme/components/modal";
@use "./src/theme/components/productDescription";
@use "./src/theme/components/segment";
@use "./src/theme/components/stage";

/* Device frame */
@use "./src/theme/components/device_frames/ipad";
@use "./src/theme/components/device_frames/iphone";
@use "./src/theme/components/device_frames/laptop";

/* Custom (Glass) UI */
/*
@use "./src/theme/custom/glass";
@use "./src/theme/custom/ai_search";
@use "./src/theme/custom/create_media";
@use "./src/theme/custom/forgot_password";
@use "./src/theme/custom/intro_card";
@use "./src/theme/custom/login";
@use "./src/theme/custom/register";
@use "./src/theme/custom/project_setup";
*/

/* Ionic */
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

spline-viewer a#logo {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

*,
::before,
::after {
  box-sizing: border-box;
}

:focus {
  outline: none !important;
  outline-offset: 0px !important;
}

swiper.swiper {
  display: block !important;

  .swiper-slide {
    display: block !important;
  }
}

.cal-day-view,
.cal-week-view,
.cal-month-view {
  background-color: var(--ion-background-color) !important;
  --background: var(--ion-background-color) !important;
}

.cal-month-view .cal-open-day-events,
.cal-month-view .cal-cell-row:hover,
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: var(--ion-item-background) !important;
  --background: var(--ion-item-background) !important;
}

.cal-week-view .cal-hour-odd {
  background-color: var(--ion-background-color) !important;
  --background: var(--ion-background-color) !important;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: var(--ion-color-medium) !important;
  --background: var(--ion-color-medium) !important;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: var(--ion-color-medium) !important;
}

.cal-week-view .cal-time-events,
.cal-week-view .cal-day-headers,
.cal-week-view .cal-days,
.cal-month-view .cal-days {
  border-color: var(--ion-color-medium) !important;
}

.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: var(--ion-color-medium) !important;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: var(--ion-color-medium) !important;
}

.cal-week-view {
  border-top-color: var(--ion-color-medium) !important;
}

.cal-month-view .cal-days .cal-cell-row,
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: var(--ion-color-medium) !important;
}

.cal-month-view .cal-open-day-events {
  box-shadow: none;
  color: var(--ion-color-dark);
}

.form.useCase:before {
  background-size: 70% !important;
}

.monthview-selected.monthview-current {
  background-color: var(--ion-color-primary) !important;
}

h1,
h2,
h3,
ion-card-title,
ion-card-subtitle {
  font-weight: 600;
}

blockquote {
  width: 100%;
  margin: 0px;
}

ion-header {

  ion-buttons {
    .headerUserPic {
      margin-top: -1px;
      margin-right: -1px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      overflow: hidden;
      --background: #fff;
      background: #fff;
    }
  }

  ion-title {
    font-weight: 700;

    &[size="large"] {
      &.md {
        font-size: 26px;
      }
    }
  }
}

ion-card-content blockquote {
  margin-bottom: 15px;
}

pipeline-header-suggestions-toolbar {
  &.top {
    display: none;
  }
}

$i: 2;

@while $i < 6 {
  $delay: $i * 15 - 30;

  @keyframes page-#{$i} {
    #{0 + $delay}% {
      transform: rotateY(180deg);
      opacity: 0;
    }

    #{20 + $delay}% {
      opacity: 1;
    }

    #{35 + $delay}%,
    100% {
      opacity: 0;
    }

    #{50 + $delay}%,
    100% {
      transform: rotateY(0deg);
    }
  }

  $i: $i + 1;
}

ion-app,
ion-nav,
ion-tab,
ion-tabs,
.app-root,
.ion-page {
  &.introjs-fixParent {
    contain: layout size style !important;
  }
}

ion-content {


  .cdk-wrapper {
    position: relative;
    left: 0px;
    top: 0px;
    height: 100%;
    min-height: 80vh;
    width: 100%;
    max-width: 100vw;
  }

  cdk-virtual-scroll-viewport {
    height: 100%;
    min-height: 82.5vh;
    width: 100%;
    max-width: 100vw;

    .cdk-virtual-scroll-content-wrapper {
      max-width: 100% !important;
    }

  }

  ion-spinner {
    margin: 30vh auto;
    display: block;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

html.light {

  ion-router-outlet,
  ion-modal {
    --background: var(--ion-background-color, #f9f9f9);
    background: var(--ion-background-color, #f9f9f9);

    ion-content {
      --background: var(--ion-background-color, #f9f9f9);
      background: var(--ion-background-color, #f9f9f9);
    }

    ion-header,
    ion-footer {
      --background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
      background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
    }
  }
}

@media (prefers-color-scheme: light) {

  ion-router-outlet,
  ion-modal {
    --background: var(--ion-background-color, #f9f9f9);
    background: var(--ion-background-color, #f9f9f9);

    ion-content {
      --background: var(--ion-background-color, #f9f9f9);
      background: var(--ion-background-color, #f9f9f9);
    }

    ion-header,
    ion-footer {
      --background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
      background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
    }
  }
}

html:not(.light) {
  @media (prefers-color-scheme: dark) {

    ion-router-outlet {
      --background: var(--ion-background-color, #000);
      background: var(--ion-background-color, #000);
    }

    ion-router-outlet,
    ion-modal {

      ion-content {
        --background: var(--ion-background-color, #000);
        background: var(--ion-background-color, #000);
      }

      ion-header,
      ion-footer {
        --background: var(--ion-toolbar-background, var(--ion-background-color, #000));
        background: var(--ion-toolbar-background, var(--ion-background-color, #000));
      }
    }
  }
}

.excerpt-label,
.excerpt-p {
  overflow: hidden;
  -moz-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  img,
  figure,
  iframe,
  video,
  embed,
  table,
  ol,
  ul {
    display: none;
  }
}

#appleid-signin {
  float: left;
  width: 100%;
  position: relative;
  display: grid;
  margin: auto;
  margin-top: 5px;
  text-align: center;
  left: 0px;
  right: 0px;

  >div {
    margin: auto;
    display: block;
    width: 50vw !important;
    max-width: 200px !important;
  }
}

ion-card,
div.card.ion-card {
  --box-shadow: none;
  box-shadow: none;
  border-radius: 10px;

  ion-card-header {
    ion-card-title {
      --color: var(--ion-color-primary);
      color: var(--ion-color-primary);

      @media screen and (max-width: 1080px) {
        font-size: 1.5rem;
      }

    }
  }

}

@media (prefers-color-scheme: dark) {
  .introjs-overlay {
    background: #000;
  }

  .ql-snow {
    .ql-picker {
      color: var(--ion-color-dark);
    }

    .ql-picker-options {
      background: var(--ion-background-color);
      border-color: transparent;
    }

    &.ql-toolbar {
      button {
        svg {
          filter: invert(100%);
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {


  ion-router-outlet {
    --side-max-width: 270px;
  }
}

ion-button.subCategory {
  margin-right: 4px;
  zoom: 0.9;
  font-weight: 600;
  --font-size: 16px;
  font-size: 16px;
  --padding-top: 7px;
  --padding-bottom: 7px;
  --padding-start: 11px;
  --padding-end: 13px;

  ion-icon {
    margin-right: 3px;
  }
}

.before,
.after {
  content: "";
  position: fixed;
  left: 0px;
  width: 100vw;
  height: 15vh;
  z-index: 2;

  &.before {
    top: -1px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 75%);
  }

  &.after {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%);
    bottom: -1px;
    height: 20vh;
    opacity: 0.9;
  }
}

html.dark {

  .before {
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 75%);
  }

  .after {
    background: linear-gradient(0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%);
  }
}

html.md {

  .before,
  .after {
    display: none;
  }
}

.scroll-row {
  flex-wrap: nowrap;
  overflow-x: scroll !important;
  overflow-y: hidden;
}

.emptyCard,
.selectProjectCard,
.noEntriesCard {
  text-align: center !important;

  ion-icon {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 40px;
  }
}

.left-align-buttons .action-sheet-button-inner {
  justify-content: flex-start;

  ion-icon {
    margin-right: 15px !important;
  }
}

.list-md {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media screen and (min-width: 769px) {
  .col-size-12 {
    max-width: 50% !important;
  }
}

@media screen and (min-width: 1025px) {

  .col-size-12:not(.peopleCol),
  .col-size-8:not(.peopleCol),
  .col-size-6:not(.peopleCol),
  .col-size-4:not(.peopleCol) {
    max-width: 33.333% !important;
  }

  .peopleCol {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.sponsoredPostItem {
  margin-top: -12px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ion-label {
    margin-top: 5px;
    margin-bottom: 5px;

    small {
      float: left;
      margin-top: -3px;
      margin-bottom: 5px;
      font-size: 13px;
    }
  }
}

.videoMode .sponsoredPostItem {
  margin-top: 0px;
  margin-bottom: -20px;
}

ion-chip.sponsored {
  position: absolute;
  z-index: 1;
  margin: 5px;
  left: 0px;
  top: 0px;
  background: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  --color: var(--ion-color-primary-contrast);
  --font-size: 12px;
  font-size: 12px;
  --min-height: 12px;
  min-height: 12px;
  --font-weight: 600;
  font-weight: 500;
  --padding-top: 2px;
  --padding-bottom: 2px;
  height: auto;
}

.swiper-slide {
  overflow-y: scroll;
  display: block;
}

.paypal-checkout-sandbox {
  top: var(--ion-safe-area-top) !important;
}

#menuLogo {
  padding: 30px 0px;
  height: 112px;
  margin: auto;
  display: block;

  @media (prefers-color-scheme: dark) {
    filter: invert(100%);
  }
}

.plt-ios #menuLogo {
  height: 90px;
}

.descriptionText {
  --min-height: auto;
  margin-left: -15px;
}

.basketBtn {
  top: 6.5vh;
  z-index: 99 !important;

  ion-fab-button {
    position: relative !important;
    z-index: 99 !important;
  }
}

ion-loading {
  --spinner-color: var(--ion-color-dark-contrast);

  .loading-wrapper {
    --background: transparent !important;
    background: transparent !important;
    --box-shadow: none !important;
    box-shadow: none !important;
  }
}

ion-skeleton-text {
  &.image {
    width: 100%;
    height: 100%;
  }
}

.cpmPopup {
  --width: 60vw;
}

.cardOptionsPopover,
.postInformationPopover {
  --width: 65vw;
  --max-width: 350px;
  max-width: 350px;
}

.postReaderAppearancePopup {
  --min-width: 250px;
  --width: 250px;
}

ion-input.usernameInput {
  input {
    --padding-top: 0px;
  }
}

.loadingDetector {
  width: 0px;
  height: 0px;
  opacity: 0;
  visibility: hidden;
}

.video-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -1;

  video {
    width: 100vw;
    min-width: 100vw;
    max-height: 100vh;
  }
}

.accelerated {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.no-entries {
  opacity: 0.8;
  margin: 25vh auto;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .ion-focusable:hover {
    cursor: pointer !important;
  }
}

.imgWrapper {
  height: 30vh;
  overflow: hidden;

  .headerImage {
    min-height: 100%;
    min-width: 100%;
    max-height: auto;
    max-width: auto;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

input[type="file"].file-upload {
  display: none;
}

.custom-file-upload {
  border: 1px solid var(--ion-color-dark);
  border-radius: 5px;
  padding: 6px 8px 1px 12px !important;
  display: inline-block;
  padding: 0px;
  cursor: pointer;

  ion-skeleton-text {
    position: absolute;
    top: -10px;
    left: 0px;
    width: 100%;
    height: calc(100% + 20px);
    z-index: 0;
  }

}

app-discover,
app-home,
app-local,
app-people,
app-profile {
  .introjs-tooltip {
    .introjs-tooltip-header {
      display: none;
    }

    .introjs-tooltiptext {
      padding: 0px !important;
    }

    .introjs-tooltipbuttons {
      border-top: 0px;
      padding: 0px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 5px;
    }
  }
}

ion-buttons {

  ion-button,
  ion-menu-button,
  ion-back-button {
    --color: var(--ion-background-white);
  }
}

/* iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .cover {
    top: 92px;
  }

  .settingsFab {
    margin-top: 40px;
  }

  app-profile ion-content>ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}

/* iPhone 11 */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .cover {
    top: 92px;
  }

  .settingsFab {
    margin-top: 40px;
  }

  app-profile ion-content>ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}

/* iPhone 11 Pro */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .cover {
    top: 92px;
  }

  .settingsFab {
    margin-top: 40px;
  }

  app-profile ion-content>ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}

/* iPhone 11 Pro Max */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .cover {
    top: 92px;
  }

  .settingsFab {
    margin-top: 40px;
  }

  app-profile ion-content>ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}